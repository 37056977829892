<template>

     <v-card outlined class="vcardWhite" color="transparent">
    
      <v-card-actions>
 
        <v-col cols="12">
              <v-btn 
              elevation="2"
              outlined
              block
              :loading  = "loadingAllCandidates"
              :disabled = "candidateCount == 0"
              @click    = "showAllCandidates"
              class     = "useFont fontSize16 text-none">
              View All
              <v-badge v-if = "candidateCount > 0"
                color      = "error"
                :content   = "candidateCount"
                inline
              ></v-badge>
              Candidates
              </v-btn>
        </v-col>


      </v-card-actions>


      <v-card-text>
       
        <v-form ref="filterForm" v-model="valid" lazy-validation>
    
          <v-select
            clearable
            v-model        = "store.filter.location"
            :items         = "locations"
            label          = "Candidates by Location"
            @input         = "applyFilter"
             class         = "useFont fontSize16"
            outlined 
            color           =blue 
            background-color=white
          ></v-select>

          <v-select
            clearable
            v-model    = "store.filter.service"
            :items     = "services"
            label      = "Candidates by Service"
            @input     = "applyFilter" 
            class      = "useFont fontSize16" 
            outlined 
            color           =blue 
            background-color=white
           ></v-select>

          <v-select
            clearable
            v-model     = "store.filter.skill"
            :items      = "skills"
            label       = "Candidates by Experience"
            @input      = "applyFilter" 
            class       = "useFont fontSize16" 
            outlined 
            color           =blue 
            background-color=white
          ></v-select>

          <v-select
            clearable
            v-model    = "store.filter.jobTitle"
            :items     = "jobTitles"
            label      = "Candidates by JobTitle"
            @input     = "applyFilter" 
            class      = "useFont fontSize16" 
            outlined 
            color      = blue 
            background-color=white
           ></v-select>

        </v-form>
        
        <v-card-actions>
          <v-row dense>
            
            <v-col cols="12">
              <div v-if = "shortListCount > 0">
                 <v-btn text @click="reset" class="fontSize16 text-none" block >Clear Filters</v-btn>
              </div>
            </v-col>



            <v-col cols="12">
         
              <div v-if = "shortListCount > 0">
                <v-btn 
                   elevation="2"
                   outlined
                   :loading  = "loadingFilteredCandidates"
                   :disabled = "disableButton"
                   @click    = "showFilteredCandidates"
                   class     = "useFont fontSize16 text-none" block >
                   View 
                   <v-badge v-if = "shortListCount > 0"
                      color      = "error"
                      :content   = "shortListCount"
                      inline
                  ></v-badge>
                  Filtered Candidates
                </v-btn>
              </div>
         
              </v-col>
            

          </v-row>
        </v-card-actions>

      </v-card-text>
  
    </v-card>
  
</template>

<script>

import axios            from  "axios";
import { store }        from  "@/store/store"
import { apiResource }  from  "@/store/apiResource"


export default {  

  name        : 'plHome',

  mounted() {
      this.clearShortList();
      this.clearSelected();
      this.clearFilter();
  },

  computed    : {

          skills() {
              return ( store.candidates.skills ) ? store.candidates.skills.sort()  :  [];
          },

          locations() {
              return ( store.candidates.locations ) ? store.candidates.locations.sort()  : [];
          },

          jobTitles() {
             return ( store.candidates.jobTitles ) ? store.candidates.jobTitles.sort()  : [];
          },
         
          services() {
              return  ( store.candidates.services ) ? store.candidates.services.sort()  : [];
          },

          disableButton() {
              return   ( store.shortList.length == 0 ) ? true : false;
          },

          shortListCount() {
              return( store.shortList.length );
          },

          candidateCount() {
              return ( this.store.candidates.count )  ? this.store.candidates.count : 0;
          },

          isFilterSet() {
            
            var CurrentfilterLength  = 0;
            CurrentfilterLength  =+ this.store.filter.location.length;
            CurrentfilterLength  =+ this.store.filter.skill.length;
            CurrentfilterLength  =+ this.store.filter.service.length;
            CurrentfilterLength  =+ this.store.filter.jobTitle.length;
 
            if ( CurrentfilterLength > 0 ) 
                 return true;
            else
                 return false;
          }
 
  },

  methods     : {
    
      showFilteredCandidates() {
          this.$router.push( { name : "shortlist"});
      },

      showAllCandidates() {
          this.runshortListAll();
      },
  
      runshortListAll : async function() {

        this.loadingAllCandidates = true;
        const  url =  apiResource.getResourceUrl("shortlistAll");
        axios.get( url )
        .then( response => {
                  if ( response.data ) {
                       this.loadingAllCandidates = false;
                       this.store.shortList = response.data;
                       this.$router.push({ name : "shortlist"});
                }
        }).catch((error) => { 

          const errorMessage            =   `${error.response.data.message} Status = ${error.response.status}`;
          this.store.errorSnackBar.color   = "error";
          this.store.errorSnackBar.text    = errorMessage;
          this.store.errorSnackBar.display = true;
          console.log( errorMessage );

          if (error.response) {
    
             console.log( "Data :" , error.response.data);
             console.log( "Status :" + error.response.status);
     
          } else if (error.request) { // The request was made but no response was received
             console.log(error.request);
          } else {// Error on setting up the request
             console.log('Error', error.message);
          }

        });

      },

      isRequestNull(){
        
        let test = 0;

        ( !this.store.filter.location    || this.store.filter.location.length == 0  )    ? null : test += 1;
        ( !this.store.filter.skill       || this.store.filter.skill.length == 0  )       ? null : test += 1;
        ( !this.store.filter.service     || this.store.filter.service.length == 0  )     ? null : test += 1;
        ( !this.store.filter.jobTitle    || this.store.filter.jobTitle.length == 0  )    ? null : test += 1;

        return ( test == 0 ) ? true : false ;

      },

      applyFilter() {

          this.clearShortList();
          this.clearSelected();
         
          this.$nextTick(() => {
             if ( this.isRequestNull() == false ) {
                  this.runShortlist();
            }
          });

      },

      clearShortList() {
             this.store.shortList  = [];
      },

      clearSelected() {
             this.store.selected = [];
      },

      clearFilter() {
       
         this.store.filter  =  {
                                 skill      : '',
                                 jobTitle   : '',
                                 location   : '',
                                 service    : '',
                               }


      },
      
      runShortlist : async function() {

       this.loadingFilteredCandidates = true;
 
       const  url =  apiResource.getResourceUrl("shortlist");
       axios.post( url, this.store.filter )
       .then( response => {
            this.loadingFilteredCandidates = false;
         
            if ( response.data ) {
                this.store.shortList = response.data;
            }

        }).catch((error) => { 

          const errorMessage            =   `${error.response.data.message} Status = ${error.response.status}`;
          this.store.errorSnackBar.color   = "error";
          this.store.errorSnackBar.text    = errorMessage;
          this.store.errorSnackBar.display = true;
          console.log( errorMessage );

          if (error.response) { 
            console.log("Data :" , error.response.data);
            console.log("Status :" + error.response.status);
          } else if (error.request) { // The request was made but no response was received
            console.log(error.request);
          } else {// Error on setting up the request
            console.log('Error', error.message);
          }
        });
    

      },

      isStringSet( myStr ){
          if (myStr === null || myStr.trim() === "") {
               return false;
          } else {
               return true;
          }
      },

      reset () {
             this.$refs.filterForm.reset()
      },
        
      resetValidation () {
            this.$refs.filterForm.resetValidation()
      },

  },

  data() {
    return {
        store,
        loadingFilteredCandidates : false,
        loadingAllCandidates      : false,
        valid   : false
     };
  },

}

</script>

<style scoped>

.actionButton{
  background-color: black !important;
  color: #FFFFFF !important; 
}

label{
  color:black !important;
}

.vcardWhite{
  background: white !important;
}
</style>