import { render, staticRenderFns } from "./plFooter.vue?vue&type=template&id=39cb5868&scoped=true"
import script from "./plFooter.vue?vue&type=script&lang=js"
export * from "./plFooter.vue?vue&type=script&lang=js"
import style0 from "./plFooter.vue?vue&type=style&index=0&id=39cb5868&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39cb5868",
  null
  
)

export default component.exports